import { Routes, Route } from 'react-router-dom';
import Default from './default';
import Janploog from './team/janploog';

import './App.css';

const App = () => {
	return (
		<>
			<Routes>
				<Route path='/' element={<Default />} />
				<Route path='/janploog' element={<Janploog />} />
				{/* <Route path="/products" element={<Products />} />
          <Route path="/about" element={<About />} /> */}
			</Routes>
		</>
	);
};

export default App;
