// import logo from './logo.svg';
import logo from './assets/logos/p_logo.png';
import './App.css';

function Default() {
	return (
		<div className='App'>
			<header className='App-header'>
				{/* <img src={logo} className='App-logo' alt='logo' /> */}
				<img src={logo} className='' alt='logo' />
				<h3>PRIVAPI GmbH</h3>
			</header>
		</div>
	);
}

export default Default;
