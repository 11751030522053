import React from 'react';
// import logo from '../assets/logos/p_logo.png';
import './janploog.css';

function Janploog() {
  return (
    <>
      {/* <img src={logo} className='' size='small' alt='logo' />
			<div>Jan Chmater    ristian Ploog</div> */}

      <div class='section-7 wf-section'>
        <div class='container-15 w-container'>
          <img
            src='p_logo.png'
            loading='lazy'
            width='116'
            sizes='(max-width: 479px) 112px, 116px'
            srcset='
          p_logo.png 500w,
          p_logo.png       512w
					'
            alt='PRIVAPI GmbH'
            class='image-10'
          />
        </div>
      </div>
      <div class='wf-section'>
        <div class='w-container'>
          <h1 class='heading-24'>
            Jan Christian Ploog
            <br />
          </h1>
          <div class='text-block-7'>
            Geschäftsführer
            <br />‍<br />
            PRIVAPI GmbH
            <br />
            Kastanienweg 45
            <br />
            63263 Neu-Isenburg
            <br />
            <br />
            Mobil: +49 172 7716222
            <br />
            Email: jan@privapi.io
            <br />
            Web: www.privapi.io
          </div>
        </div>
      </div>
      <div class='wf-section'>
        <div class='container-16 w-container'>
          <a href='vcf/janploog.vcf' class='btnvcf1 w-button'>
            Visitenkarte importieren
          </a>
          <a
            href='https://www.linkedin.com/in/janploog'
            target='_blank'
            class='btnvcf1 w-button'
          >
            LinkedIn
          </a>
          <a
            href='https://www.privapi.io'
            target='_blank'
            class='btnvcf1 w-button'
          >
            Webseite
          </a>
        </div>
      </div>
    </>
  );
}

export default Janploog;
